// Insert your styling here.
.navbar {
  margin-bottom: 0;
}
.navbar-nav {
  > li > a {
    text-align:center;
  }
  text-transform: uppercase;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  @media (min-width: @grid-float-breakpoint) {
    .pull-right();
    > li > a {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
.navbar-btn {
 
}