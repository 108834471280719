// Bootstrap library.
@import 'bootstrap.less';
@import 'bootswatch.less';
// Base-theme overrides.
@import 'overrides.less';

// Theme specific.
@import 'header.less';
@import 'content.less';
@import 'footer.less';

// Fonts 
@import url(http://fonts.googleapis.com/css?family=Ubuntu:400,700);
@import '../font-awesome/less/font-awesome.less';

// Mixins
.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.border-radius (@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}