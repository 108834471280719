// Insert your styling here.
.header-bg {
  .cover();
  .tint {
    padding-bottom: 35.416667%;
    //background-color: rgba(1,170,90,.2);
    min-height: 90vh;
  }
}
.not-front {
  background: #f5f5f5;
  .header-bg {
    background-position: center top !important;
    .tint {
      padding-bottom: 14.6875%;
      min-height: 150px;
    }
  }
}

.flexslider {
  margin: 0;
  border: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: none;
  .border-radius(0);
}

/* Page node */
.node-type-pool-page {
  .group-title.field-group-div {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .flex-control-nav {
    bottom: 30px;
    z-index: 5;
  }
  .group-wrapper.field-group-div {
    background: #fff;
  }
  .field.field-name-body {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
}

/* Event node */
.node-type-event {
  .group-wrapper.field-group-div {
    background: #fff;
  }
  .field.field-name-body {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  .field.field-name-title-field {
    padding-left: 30px;
    padding-right: 30px;
  }
  .field.field-name-post-date {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.flex-direction-nav {
  a {
    line-height: 40px;
  }
}
.flex-control-paging li a {
  background: #c7c7c7;
  &.flex-active {
    background: @brand-primary;
  }
}
.carousel li {
  margin-right: 0;
}
.btn {
  text-transform: uppercase;
  &.btn-primary {
    a {
      color: #fff;
      text-decoration: none;
    }
  }
}
.block-title {
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    color: @brand-primary;
}

#block-bean-title-event-node {
  margin-top: 30px;
  margin-bottom: 30px;
  h1 {
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    color: @brand-primary;
    span {
      font-size: 14px;
      font-style: italic;
      color: #5b5b5b;
      font-weight: normal;
      text-transform: none;
    }
  }
}
.node.node-event {
  img {
    width: 100%;
    height: auto;
  }
}
.field.field-name-title-field {
  display: inline-block;
  h1 {
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    color: @brand-primary;
  }
}
.field.field-name-field-subtitle {
  display: inline-block;
  margin-left: 15px;
  position:relative;
  font-style: italic;
  color: #5b5b5b;
  &:before {
    content: "|";
    display: inline-block;
    position: absolute;
    left: -15px;
    bottom: 0;
    font-size: 23px;
    color: @brand-primary;
    font-style: normal;
  }
}

/* Events */
#block-views-events-block-1 {
  background-color: #f5f5f5;
  padding-top: 60px;
  padding-bottom: 60px;
  .view-header {
    margin-bottom: 30px;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    h2 {
      font-size: 23px;
      font-weight: bold;
      text-transform: uppercase;
      color: @brand-primary;
      span {
        font-size: 14px;
        font-weight: normal;
        font-style:italic;        
        text-transform: none;
        color: #5b5b5b;
      }
    }
  }
  .block-title {
    .container();
  }
  .view-events {
    .view-content .flexslider {
      .flex-viewport {
    	  max-width: 1080px; //1140px;
    	  margin: 0 auto;
  	 	  .slides > li {
  	 	    > div {
   			   margin-right: 15px;
   			   margin-left: 15px;
           background-color: #fff;
           font-size: @font-size-small;
           line-height: 1.6;
           .group-field-wrapper {
             padding: 15px;
             .field.field-name-title-field {
               a {
                 color: #5b5b5b;
                 text-decoration:none;
                 font-weight: 600;
               }
             }
           }
          } 
        }
      }
    }
  }
}

#block-views-events-block-2 {
  background: #fff;
  margin-top: 30px;
  @media (min-width: @screen-sm-min) {
    margin-top: 117px;
  }
}
.logged-in #block-views-events-block-2 {
  @media (min-width: @screen-sm-min) {
   margin-top: 170px;
  }
}

/* Frontpage slide */
#block-views-frontpage-slideshow-block {
  .flexslider {
    position: relative;
    -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
    .flex-caption {
      max-width: 930px;
      padding-left: 15px;
      padding-right: 15px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      color: #fff;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);
      @media (min-width: @screen-sm-min){
        font-size: 18px;
      }
      .views-field.views-field-title-field {
        font-weight: bold;
        font-size: 28px;
        text-transform: uppercase;
        font-family: 'Ubuntu', sans-serif;
        @media (min-width: @screen-sm-min) {
          font-size: 39px;
        }
        @media (min-width: @screen-md-min) {
          font-size: 68px;
        }
      }
      .btn {
        text-shadow: none;
        &.btn-primary {
          @media (min-width: @screen-sm-min) {
            padding: 12px 25px;
          }
        }
      }
      .views-field.views-field-body {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        margin-bottom: 30px;
      }
    }
  }
}
#block-views-frontpage-slideshow-block-1 {
  .flexslider {
    position: relative;
    -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
    .flex-caption {
      max-width: 1170px;
      padding-left: 15px;
      padding-right: 15px;
      position: absolute;
      bottom: 10%;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: left;
      color: #fff;
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
      -webkit-transform: translateY(-10%);
      -moz-transform: translateY(-10%);
      transform: translateY(-10%);
      .views-field.views-field-title-field {
        font-weight: bold;
        font-size: 28px;
        text-transform: uppercase;
        font-family: 'Ubuntu', sans-serif;
        @media (min-width: @screen-md-min) {
          font-size: 39px;
        }
      }
    }
  }
}

/* Calendar */
#block-views-calendar-block-3 {
  padding-top: 60px;
  .view.view-calendar {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-calendar {
  .view-calendar {
    margin-top: 60px;
  }
  .view-footer {
    text-align: center;
    .btn-default {
      color: #B2B2B2; 
      background-color: transparent;
      border-color: #CACACA;
      &:hover {
        background-color: transparent;
        color: #696969;
        border-color: #696969;
      }
    }
  }
}

.calendar-calendar .week-view .full {
  td {
    padding: 0;
  }
  div.single-day div.weekview {
    border: 0 none;
    background: none;
  }
  .days {
    width: 14%;
    text-align: left;
    font-weight: normal;
    span {
      font-weight:bold;
      .pull-right;
    }
  }
  th {
    height: 47px;
    border-top: 0;
    border-bottom: 0;
    border-right: 1px solid #ccc;
  }
  div.calendar-agenda-hour {
    text-align: center;
    .calendar-hour {
      line-height: 47px;
    }
  }
  div.calendar-agenda-hour, div.single-day {
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
  }
  div.single-day {
    .half-hour {
      border: 0;
    }
  }
  div.calendar-agenda-hour,
  div.calendar-agenda-items {
    border: 0;
  }
}
.calendar-calendar .week-view .single-day-footer {
  border: 0;
  background: none;
}

.view {
  .date-nav-wrapper {
    .date-heading {
      h3 {
        font-size: 15px;
        line-height: 1.2;
        text-transform: uppercase;
        span {
          display: block;
          font-size: 26px;
        }
      }
    }
    .date-prev, .date-next {
      background: none;
    }
  }
}

.calendar-calendar {
  div.calendar {
    font-size: 13px;
  }
  th.days {
    background-color: transparent;
    padding: 10px;
  }
  .week-view #single-day-container {
    border: 0 none;
    height:auto;
  }
}
div.view-item.view-item-calendar {
  opacity: 0.9;
}
.calendar-calendar .week-view div.single-day .i_0.md_0 .view-item .calendar {
  width: auto !important;
}
.calendar-calendar .week-view .full div.single-day div.weekview {
  padding: 10px;
  color: #2e2e2e;
  a {
    color: #2e2e2e;
    font-weight: normal;
  }
}

.view-calendar {
  .view-header {
    @media (min-width: @screen-lg-min){
      margin-left: 51px;
    }
    h2 {
      font-size: 23px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 40px;
      color: @brand-primary;
      span {
        font-size: 14px;
        font-weight: normal;
        font-style:italic;        
        text-transform: none;
        color: #5b5b5b;
      }
    }
  }
  .pager {
    margin: 0;
    text-transform: uppercase;
  }
  .date-nav-wrapper {
    .date-prev,
    .date-next {
      a {
        font-size: 15px;
        font-weight: normal;
        margin-left:0;
        margin-right: 0;
        padding-left: 5px;
        padding-right: 5px;
      }
    } 
    .date-prev {
      left: 0;
      right: auto;
    }
    .date-nav {
      border-bottom: 8px solid @brand-primary;
      padding-bottom: 15px;
    }
  }
}

.modal-title {
  color: #676767;
  span {
    font-weight: bold;
  }
}
.modal-body {
  .field.field-name-body {
    .field-items {
      float:none;
    }
  }
}

.field.field-name-post-date {
  font-size: 13px;
  text-transform: uppercase;
  color: @brand-primary;
  .field-label {
    font-weight: normal;
  }
}
.field.field-name-title-field {
  h5 {
    font-weight: bold;
  }
}

.page-header {
  margin-top: 30px;
  border: 0;
}

#booking-entityform-edit-form {
  label {
    text-transform: uppercase;
  }
  .tabledrag-toggle-weight-wrapper {
    display: none;
  }
  td {
    padding: 0;
  }
  .date-padding {
    padding: 0;
  }
  .field-multiple-drag {
    display: none;
  }
  thead label {
    display: none;
  }
  .table > thead > tr > th,
  .table > tbody > tr > td {
    border: 0;
  }
  .draggable {
    background-color: transparent !important;
  }
  .form-item-field-booking-date-und-0-value-time {
  }
  .form-item-field-booking-date-und-0-value-time,
  .form-item-field-booking-date-und-0-value2-time {
    
  }
  .grippie {
    display: none;
  }
  .btn-info {
    background-color: transparent;
    border-color: transparent;
    color: #5b5b5b;
    font-style:italic;
    text-transform: none;
    margin: 0;
    padding: 0;
    &:before {
      content: "+";
    }
  }
  .form-type-date-combo.form-group {
    margin: 0;
    > label {
      display: none;
    }
  }
  .form-type-date-popup .form-type-textfield {
    input {
      max-width: 100px;
    }
    label {
      &:after {
        content: "*";
        color: red;
      }
    }
  }
  .form-type-date-popup .form-type-textfield:first-of-type {
    position: relative;
   	&:after {
   		content: @fa-var-calendar;
   		display: inline-block;
   		font: normal normal normal 18px/1 FontAwesome;
   		color: #373737;
   		position: absolute;
   		top: 55%;
   		right: 15px;
   	}
    input {
      max-width: 200px;
    }
  }
  .form-type-date-popup .form-type-textfield:last-of-type {
    label {
      visibility:hidden;
    }
    
    margin-left: 15px;
    position:relative;
    &:before {
      content: "-";
      font-weight:bold;
      position:absolute;
      left: -12px;
      top: 50%;
    }
  }
  #edit-actions {
    text-align: right;
    .btn-default,
    .btn-primary {
      color: #B2B2B2; 
      background-color: transparent;
      border-color: #CACACA;
      &:hover {
        background-color: transparent;
        color: #696969;
        border-color: #696969;
      }
    }
  }
  .form-required {
    color: red;
  }
}

.node-type-form {
  .field-name-body {
    color: #848484;
    line-height: 1.7;
  }
}


