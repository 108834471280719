// Insert your styling here.
.footer {
  padding-top: 40px;
  background: #0074a4 url('../images/footerbild.jpg') no-repeat;
  color: #fff;
  .cover();
  .block-title {
	  margin-top: 0;
	   color: #fff;
	   font-size: 18px;
	   font-weight: bold;
	   text-transform: uppercase;
   }
   .form-control.form-text {
 	   background: @brand-primary;
	   border: 1px solid @brand-primary;
	   border-radius: 5px;
	   color: #fff;
	   padding-left: 30px;
	   font-style:italic;
   }
   .form-control.form-textarea {
	   background: @brand-primary;
	   border: 1px solid @brand-primary;
	   border-radius: 5px;
	   color: #fff;
     padding-left: 30px;
	   font-style:italic;
   }
   .resizable-textarea .grippie {
     display: none;
 	   background: @brand-primary;
	   border: 1px solid @brand-primary;
	   border-radius: 0 0 5px 5px;
   }
   .form-actions.form-wrapper.form-group {
	   text-align: right;
   }
   #edit-field-name .form-type-textfield {
   	position:relative;
   	&:before {
   		content: @fa-var-user;
   		display: inline-block;
   		font: normal normal normal 18px/1 FontAwesome;
   		color: #fff;
   		position: absolute;
   		top: 10px;
   		left: 10px;
   	}
   }
   #edit-field-email .form-type-textfield {
   	position:relative;
   	&:before {
   		content: @fa-var-at;
   		display: inline-block;
   		font: normal normal normal 18px/1 FontAwesome;
   		color: #fff;
   		position: absolute;
   		top: 10px;
   		left: 10px;
   	}
   }
   #edit-field-message .form-type-textarea {
   	position:relative;
   	&:before {
   		content: @fa-var-pencil;
   		display: inline-block;
   		font: normal normal normal 18px/1 FontAwesome;
   		color: #fff;
   		position: absolute;
   		top: 10px;
   		left: 10px;
   	}
   }
   ::-webkit-input-placeholder {
      color: #fff;
   }

   :-moz-placeholder { /* Firefox 18- */
      color: #fff;  
   }

   ::-moz-placeholder {  /* Firefox 19+ */
      color: #fff;  
   }

   :-ms-input-placeholder {  
      color: #fff;  
   }
   #block-bean-copyright {
     margin-top: 30px;
   }
   #block-bean-sociala-lnkar {
     margin-top: 30px;
     text-align: right;
     .field.field-name-field-text {
       a {
         display: inline-block;
         width: 35px;
         height: 35px;
         background: #fff;
         border-radius: 50%;
         text-align: center;
         line-height: 35px;
         margin-left: 15px;
       }
     }
   }
}